import $ from "jquery";

$(document).on('turbolinks:load', () => {
  const form = $("#admin_provider_form_container");
  if (form.length > 0) {
    const latestDmProviderType = form[0].dataset.latestDmProviderType.toLowerCase();
    const alertDiv = $("#dni_type_mismatch_alert");

    const checkTypeMismatch = () => {
      let currentType;
      // Verificar si estamos en el edit (campo de entrada)
      if ($("#provider_business_id_type").length > 0) {
        currentType = $("#provider_business_id_type").val().trim().toLowerCase();
      } else {
        // En el show, obtenemos el texto del elemento
        currentType = $("#business_id_type").text().trim().toLowerCase();
      }

      if (currentType === "" || currentType !== latestDmProviderType) {
        alertDiv.show();
      } else {
        alertDiv.hide();
      }
    };

    checkTypeMismatch();

    // Solo agregar el evento si estamos en el edit
    if ($("#provider_business_id_type").is("input, select")) {
      $("#provider_business_id_type").on("input change", () => {
        checkTypeMismatch();
      });
    }
  }

  if ($(".admin-benefits-info-container").length > 0){
    const toggleSwitch = (checkbox) => {

      const field = checkbox.dataset.field;
      const updateBlock = checkbox.dataset.updateBlock;
    
      const data = {};
      data[field] = checkbox.checked;
    
      const form = $("#edit_full_translation_form");
      const url = form.data('url');
    
      $.ajax({
          url: url,
          type: 'PATCH',
          data: { provider: data },
          dataType: 'json',
          success: (response) => {
              if (updateBlock) {
                  $(`#${updateBlock}`).html(response.credits_status_html);
              }
          },
          error: (xhr) => {
              const errors = xhr.responseJSON ? xhr.responseJSON.errors : ['Error desconocido'];
              console.error('Error al actualizar el campo:', errors);
          }
      });
    };
    

    $(document).on('change', '.switch input[type="checkbox"]', function() {
      toggleSwitch(this);
    });
  }
});
