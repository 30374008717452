import $ from "jquery";

$( document ).on('turbolinks:load', function() {
  if ($('.admin-financier-form').length) {

    $('#billingModal').on('hidden.bs.modal', function (e) {
      // reseteamos form billing
      $("#create_billing_form")[0].reset();
      $("#billing_commune_id").val("");
      $("#billing_commune_id").trigger("change");

      // guardamos el billing seleccionado para no perderlo al actualizar
      let selectedValue = $('#financier_billing_id').val();
      $("#financier_billing_id").empty();

      // consultamos por lista actualizada para select de billings
      let url = $("#financier_billing_id").data("url");
      $.get(url, function(data) {
        // Si no había nada seleccionado, dejamos el valor por defecto
        if(selectedValue === ""){
          $('#financier_billing_id').append("<option selected='selected'>Seleccionar facturación</option>");
        }
        data.options.forEach(function( option ){
          // Si había una opción seleccionada, la marcamos como tal
          if(option[1] === parseInt(selectedValue)){
            $('#financier_billing_id').append("<option selected value="+option[1]+">"+option[0]+"</option>");
          }
          else{
            $('#financier_billing_id').append("<option value="+option[1]+">"+option[0]+"</option>");
          }
        });
      });
    })

  }
});
