import $ from "jquery";

$(document).on("turbolinks:load", function() {
  if($("#user_form").length){
    $("#user_role").on("change", function() {
      var providerSelect = $("#user_provider_id");
      var roleValue = $(this).val();
      if (roleValue == "provider") {
        providerSelect.prop("disabled", false);
      } else {
        providerSelect.prop("disabled", true);
      }
    });

    // submit able and disabled
    $('#pass, #confirmPass').on('keyup', function() {
      $('input[name="commit"]').prop('disabled', true);
      var pass = $('#pass').val();
      var confirmPass = $('#confirmPass').val();
      if (pass == confirmPass) {
        $('input[name="commit"]').prop('disabled', false);
        $('#passErrorMessage').text('');
      }
      else {
        $('input[name="commit"]').prop('disabled', true);
        $('#passErrorMessage').text('Error: contraseñas son distintas');
      }
    });

    $('#user_role').change(function() {
        $('#user_provider_id').val('').trigger('change');
    });
  }
});
  